import Rails from "@rails/ujs"

window.removeAllChildNodes = function (parent) {
  while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
  }
}

window.getReport = function  (group, service, reload) {
  function reloadReport(_group, _service, _reload) {
    if (_reload) {
      setTimeout(function() {
        refreshReport(group, service, true);
        reloadReport();
      },
      300000)
    }
  }

  Rails.ajax({
    type: "GET", 
    url: '/report/' + group + '/' + service,
    success: (response)=> {
      const reportCard = document.getElementById('server-' + group + '-' + service);
      const reportCardLoader = document.getElementById('server-' + group + '-' + service +'-loader');
      reportCardLoader.style.display = 'none';
      removeAllChildNodes(reportCard);
      reportCard.innerHTML = response.body.innerText;
      reloadReport(group, service, reload);
    },
    error: (response) => {
      const reportCard = document.getElementById('server-' + group + '-' + service);
      const reportCardLoader = document.getElementById('server-' + group + '-' + service +'-loader');
      reportCardLoader.style.display = 'none';
      removeAllChildNodes(reportCard);

      const htmlInputs = [];
      htmlInputs.push("<td class='text-start' style='min-width:200px;max-width:200px'>&nbsp;&nbsp;")
      htmlInputs.push(service)
      htmlInputs.push("</td>")
      htmlInputs.push("<td colspan='6' style='color:red;font-weight:500'>")
      htmlInputs.push((response || {messages: []}).messages.join('. '))
      htmlInputs.push("</td>")
      htmlInputs.push("<td>")
      htmlInputs.push('<button id="server-'+group+'-'+service+'-btn" class="btn btn-outline-primary btn-small" onclick="refreshReport(\''+group+'\', \''+service+'\')">refresh</button>')
      htmlInputs.push('<span style="display:none" id="server-'+group+'-'+service+'-loader">loading...</span>')
      htmlInputs.push("</td>")
      reportCard.innerHTML = htmlInputs.join('');
      reloadReport(group, service, reload);
    }
  })
}

window.refreshReport = function (group, service, reload) {
  const reportCardLoader = document.getElementById('server-' + group + '-' + service +'-loader');
  reportCardLoader.style.display = 'inline-block';
  const reportCardBtn = document.getElementById('server-' + group + '-' + service +'-btn');
  reportCardBtn.style.display = 'none';
  getReport(group, service, reload);
}

window.exportReport = function () {
  document.getElementById('btn-export').style.display = 'none';
  document.getElementById('btn-download').style.display = 'none';
  document.getElementById('lbl-exporting').style.display = 'inline-block';

  Rails.ajax({
    type: "GET", 
    url: '/api/business-report/export',
    success: () => {
      let retryCount = 5;
      const getState = () => {
        document.getElementById('btn-export').style.display = 'none';
        document.getElementById('btn-download').style.display = 'none';
        document.getElementById('lbl-exporting').style.display = 'inline-block';
        
        setTimeout(
          function () {
            exportReportState(
              (response)=> {
                if (response.state == 'exporting') {
                  document.getElementById('lbl-exporting').style.display = 'inline-block';
                  getState();
                } else if (response.state == 'done') {
                  document.getElementById('lbl-exporting').style.display = 'none';
                  document.getElementById('btn-download').style.display = 'inline-block';
                  document.getElementById('btn-export').style.display = 'inline-block';
                } else if (response.state == 'failed') {
                  alert("Export failed, something went wrong. You may retry.")
                  document.getElementById('lbl-exporting').style.display = 'none';
                  document.getElementById('btn-export').style.display = 'inline-block';
                }
              },
              (err) => {
                retryCount -= retryCount;
                if (retryCount) getState();
              }
            ) 
          },
          3000
        );
      } 
      
      getState();
    },
    error: (err) => {
      alert('Business report export failed.');
      document.getElementById('btn-export').style.display = 'inline-block';
      document.getElementById('lbl-exporting').style.display = 'none';
    }
  })
}

window.exportReportState = function (successCallback, failCallback) {
  Rails.ajax({
    type: "GET", 
    url: '/api/business-report/export-state',
    success: successCallback,
    error: failCallback
  })
} 

window.sortTable = function(id, n, type='string') {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById(id);
  switching = true;
  // Set the sorting direction to ascending:
  dir = "asc";
  /* Make a loop that will continue until
  no switching has been done: */
  while (switching) {

    // Start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /* Loop through all table rows (except the
    first, which contains table headers): */
    for (i = 1; i < (rows.length - 1); i++) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Get the two elements you want to compare,
      one from current row and one from the next: */
      x = rows[i].getElementsByTagName("TD")[n] || {title: '-1'};
      y = rows[i + 1].getElementsByTagName("TD")[n] || {title: '-1'};
      /* Check if the two rows should switch place,
      based on the direction, asc or desc: */
      if (dir == "asc") {
        if (type=='number' && Number(x.title) > Number(y.title)) {
          shouldSwitch = true;
          break;
        }
        if (type=='string' && x.title.toLowerCase() > y.title.toLowerCase()) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      } else if (dir == "desc") {
        if (type=='number' && Number(x.title) < Number(y.title)) {
          shouldSwitch = true;
          break;
        }
        if (type=='string' && x.title.toLowerCase() < y.title.toLowerCase()) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
      and mark that a switch has been done: */
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      // Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /* If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again. */
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }

  const headers = table.getElementsByTagName('TH')
  const NUMBER_OF_COLUMNS = 8;
  for (var i=0; i<NUMBER_OF_COLUMNS;i++){
    const headerSortLegend = headers[i].getElementsByTagName('SPAN')
    if (headerSortLegend.length) headerSortLegend[0].innerHTML = ''
  }

  const headerSpans = headers[n].getElementsByTagName('SPAN');
  var headerTargetSortLegend = undefined;
  if (!headerSpans.length) {
    headerTargetSortLegend = document.createElement("SPAN");
    headerTargetSortLegend.setAttribute("class", 'sort-legend-arrow');
    headers[n].appendChild(headerTargetSortLegend);
  } else {
    headerTargetSortLegend = headerSpans[0]
  }

  headerTargetSortLegend.innerHTML = dir === 'asc' ? '&#x2191;' : '&#x2193';
}